import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';
import { EndUserPages } from '@aurora/shared-types/pages/enums';
import dynamic from 'next/dynamic';
import React, { useContext } from 'react';
import useCategoryDetails from '../../../components/community/Category/CategoryActionMenu/useCategoryDetails';
import BasePage from '../../../components/page/BasePage/BasePage';
import type { CategoryDetailsQueryVariables } from '../../../types/graphql-types';
import useNodePageSeoProps from '../../../components/useNodePageSeoProps';

const CategoryActionMenu = dynamic(
  () => import('../../../components/community/Category/CategoryActionMenu/CategoryActionMenu')
);
const NodeSubscriptionAction = dynamic(
  () => import('../../../components/nodes/NodeSubscriptionAction/NodeSubscriptionAction')
);

/**
 * Category Page
 *
 * @author Stephen McLaughry, Adam Ayres, Guido Mininno
 */
const CategoryPage: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { contextNode } = useContext(AppContext);

  const variables: CategoryDetailsQueryVariables = {
    id: contextNode.id,
    useCategoryPolicies: true,
    useCanAdminNode: true,
    useManageArticleBlog: true,
    useManageArticleTkb: true
  };

  const {
    queryResult: { data: categoryDetails },
    categoryData,
    loading: categoryInfoLoading
  } = useCategoryDetails(variables);

  const category = categoryDetails?.category;
  const nextSeoProps = useNodePageSeoProps(categoryData);

  if (categoryInfoLoading) {
    return null;
  }

  /**
   * Renders the Category action menu.
   */
  function renderCategoryActionMenu(): React.ReactElement {
    return <CategoryActionMenu category={category} />;
  }

  /**
   * Renders the node subscription action.
   */
  function renderNodeSubscribeAction(): React.ReactElement {
    return <NodeSubscriptionAction node={contextNode} />;
  }

  return (
    <BasePage
      pageId={EndUserPages.CategoryPage}
      breadcrumbActionComponents={[renderNodeSubscribeAction, renderCategoryActionMenu]}
      nextSeoProps={nextSeoProps}
    />
  );
};

export default CategoryPage;
