// import { Board, Category, GroupHub } from '@aurora/shared-generated/types/graphql-schema-types';
import type { NextSeoProps } from 'next-seo';
import {
  stripHtmlFromString,
  truncateString
} from '@aurora/shared-utils/helpers/objects/StringHelper';

export const SEO_DESCRIPTION_MAX_LENGTH = 160;

interface SeoProps {
  /**
   * Description of the context node
   */
  description?: string;
  /**
   * Avatar information of the node
   */
  avatar?: {
    url: string;
    width: number;
    height: number;
    altText: string;
  };
}

/**
 *
 * @param nodeType Type of Node
 * @param board Current board details
 *
 * @returns Seo details for the page
 */
export default function useNodePageSeoProps(node: SeoProps): NextSeoProps {
  if (!node) {
    return null;
  }

  const description = node?.description;

  const truncatedDescription = truncateString(description, SEO_DESCRIPTION_MAX_LENGTH);
  const seoDescription = stripHtmlFromString(truncatedDescription);

  const avatar = node?.avatar;

  const nextSeoProps: NextSeoProps = {
    ...(seoDescription && { description: seoDescription }),
    ...(avatar && {
      openGraph: {
        images: [
          {
            url: avatar.url,
            width: avatar.width,
            height: avatar.height,
            alt: avatar.altText || ''
          }
        ]
      }
    })
  };

  return nextSeoProps;
}
